import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { createBrowserRouter } from 'react-router-dom';
import { routes } from './routes'; // Asegúrate de que este archivo existe y tiene las rutas correctamente definidas.

const router = createBrowserRouter(routes);

const Router = () => <RouterProvider router={router} />;

export default Router;
