//  i need an Auth provider for my app

import React, { createContext, useContext, useEffect } from 'react';
import { useLocalStorage } from './useLocalStorage';
import { useGlobalContext } from './GlobalState';

import { loginApi } from '../api/auth';
// import { getAvailableLocations } from '../api/locations';
import * as Sentry from '@sentry/react';
import { getUser } from '../api/users';
const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const { state, dispatch } = useGlobalContext();
  const [user, setUser, removeValue] = useLocalStorage('user', null);
  // const navigate = useNavigate();
  // const queryClient = useQueryClient();

  useEffect(() => {
    if (user) {
      // redirect('/login');
      // console.log('user',user);
      //  redirect('/dashboard');
      //   dispatch({ type: 'LOGIN', payload: user });
    }
  }, []);

  const login = async ({ email, password }) => {
    const value = window.localStorage.getItem('user');
    const user = JSON.parse(value);
    document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    if(user){
      window.localStorage.removeItem('user');
    }
    try {
      const response = await loginApi(email, password);
      const sessionData = {
        time_zone: response.locations[0].time_zone,
        uuid: response.user.uuid,
        email: response.user.email,
        full_name: response.user.first_name + ' ' + response.user.last_name,
        single_access_token: response.user.single_access_token,
        location_id: response.locations[0].id,
        location_name: response.locations[0].name,
        master_account_id: response.locations[0].master_account_id,
        // point_of_sale_id: response.location.point_of_sale_id,
        user_id: response.user.id,
        locations: response.locations,
        country: {
          time_zone: response.locations[0].time_zone,
        },
        roles: response.user.roles,
      };
      
      const fetch_me = await getUser(response.user.id, response.user.single_access_token);
      let updateResponse = {
        ...response,
        user: fetch_me,
      }
      Sentry.setUser(user);
      setUser(sessionData);
      dispatch({ type: 'LOGIN', payload: updateResponse });
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };
  const changeLocation = (location) => {
    setUser({
      ...user,
      location_name: location.name,
      location_id: location.id,
      country: {
        id: location.country_id,
        name: location.country_name,
        currency: location.currency,
        currency_simbol: location.currency_simbol,
        time_zone: location.time_zone,
      },
    });
  };
  const logout = () => {
    // console.log('entra');
    // redirect('/login', { replace: true });
    removeValue('user');
    Sentry.setUser(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{ user, login, state, logout, changeLocation }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
