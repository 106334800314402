import React from 'react';
import ReactDOM from 'react-dom/client';
import './services/i18n';
import './assets/styles/index.css';
import { ThemeProvider } from '@material-tailwind/react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { GlobalProvider } from './context/GlobalState';
import { AuthProvider } from './context/AuthProvider';
import Router from './router/config/Router';
import { queryClient } from './router/config/queryClient';
import './router/config/sentry'; // Configuración de Sentry

const AppProviders = ({ children }) => (
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <GlobalProvider>
        <AuthProvider>
          <ThemeProvider>
            {children}
          </ThemeProvider>
        </AuthProvider>
      </GlobalProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </HelmetProvider>
);

ReactDOM.createRoot(document.getElementById('root')).render(
  <AppProviders>
    <Router />
  </AppProviders>
);