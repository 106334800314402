import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TableCustom from '../components/TableCustom';
import {
  createProduct,
  deleteProduct,
  getProduct,
  getProducts_types,
  updateProduct,
} from '../api/products';

import { USE_FORM_COUNTRIES } from '../services/Constants/FORMS';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import { CountriesColumns as columns } from '../services/Constants/LocationColumns';
import { formatError } from '../services/utils/formatError';
import { getRegion_config_types } from '../api/config';
import { openNotificationFields } from '../services/utils/openNotificationFields';
import { useAuth } from '../context/AuthProvider';
import { canUpdateProducts, isUserAdmin } from '../services/can_user';
import { useGetCountries, useGetLocations } from '../api/locations';
const TITLE = 'Países';
const TITLE_SINGULAR = 'País';
export default function Countries() {
  const {user} = useAuth()
  const FORM_COUNTRIES = USE_FORM_COUNTRIES();
  const { data, isLoading ,} = useGetCountries()
  const [filterTable, setfilterTable] = useState(isLoading ? [] : data);
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    setfilterTable(isLoading ? [] : data);
  }, [data]);
 
  const handleDelete = async (id) => {
    await useDeleteProduct.mutateAsync(id);

  };
  const openNotification = (type, description) => {
    api[type]({
      message:
        type == 'success'
          ? `El Producto se ha actualizado correctamente`
          : `Error al actualizar el producto`,
   ...openNotificationFields(type,description)
    });
  };
 
  
  const [FORM, setFORM] = useState(FORM_COUNTRIES);
  const [searchInput, setsearchInput] = useState('');
  const [openDrawer, setopenDrawer] = useState({
    visible: false,
    record: null,
  });
  useEffect(() => {
  if(openDrawer.visible == false){
    setFORM(FORM_COUNTRIES)
  }
  }, [openDrawer])
  
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    if (!openDrawer.record) {
      let product = {
        data: data,
      };
      await useCreateProduct.mutateAsync(product);
      return setopenDrawer({ visible: false, record: null });
    }
    let product = {
      id: openDrawer.record.id,
      data: data,
    };
    await useUpdateProduct.mutateAsync(product);
    setopenDrawer({ visible: false, record: null });
  };
  const handleOpenDrawer = async (visible, record) => {
    reset();
    if (visible) {
      try {
        let product;
        if (record) {
          product = await getProduct(record.id);
          FORM.forEach((item) => {
            if(item.name == 'price'){
             return setValue(item.name, parseFloat(product[item.name]));
            }
            setValue(item.name, product[item.name]);
          });
         
        }
        const product_types = await getProducts_types();
        const region_config_types = await getRegion_config_types();
    const products = data
    console.log('products',products);
        // UPDATE DE FORM_PRODUCT WITH PRODUCT_TYPES
        setFORM(
          FORM.filter((item)=>{
            if(item.name == 'recurrent' && product?.product_type_id == 2){
              return false
            }
            return item
          }).map((item) => {
            if (item.name === 'product_type_id') {
              return {
                ...item,
                options: product_types.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              };
            }
            if(item.name == 'parent_product_id'){
              return {
                ...item,
                options: products.filter((p)=>p.product_type_id !== 2 &&  !p.parent_product_id).map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              };
            }
            if (item.name === 'region_config_type_id' || item.name === 'available_region_config_type_id') {
              return {
                ...item,
                options: region_config_types.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              };
            }
            return item;
          })
        );

        setopenDrawer({ visible: visible, record: record });
      } catch (error) {
        console.error('Error opening drawer:', error);
      }
    } else {
      setopenDrawer({ visible: visible, record: record });
    }
  };
  const useUpdateProduct = useMutation({
    mutationFn: (product) => updateProduct(product.id, product.data),
    onSuccess: () => {
      openNotification('success');
      
      queryClient.invalidateQueries({
        queryKey: ['products'],
      });
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useCreateProduct = useMutation({
    mutationFn: (product) => createProduct(product.data),
    onSuccess: () => {
      openNotification('success');
      
      queryClient.invalidateQueries({
        queryKey: ['products'],
      });
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useDeleteProduct = useMutation({
    mutationFn: (id) => deleteProduct(id),
    onSuccess: () => {
      openNotification('success');
      
      queryClient.invalidateQueries({
        queryKey: ['products'],
      });
    },
    onError: (error) => {
      formatError(error, openNotification);
    },
  });
  return (
    <>
      <TableCustom
        filterTable={filterTable}
        data={data}
        openDrawer={openDrawer}
        setfilterTable={setfilterTable}
        handleOpenDrawer={handleOpenDrawer}
        title={TITLE}
        canUpdate={false}
        canCreate={false}
        title_singular={TITLE_SINGULAR}
        searchInput={searchInput}
        onSubmit={onSubmit}
        control={control}
        FORM={FORM}
        handleDelete={handleDelete}
        originalColumns={columns}
        register={register}
        handleSubmit={handleSubmit}
        setsearchInput={setsearchInput}
        isLoading={isLoading}
        mutateLoading={useUpdateProduct.isLoading || useCreateProduct.isLoading}
      />
      {contextHolder}
    </>
  );
}
