  import React, { useState, useEffect, useRef } from 'react';
import { ConfigProvider, DatePicker } from 'antd';
import es_ES from 'antd/locale/es_ES';
import { Select, Space } from 'antd';
import AddButton from '../helpers/AddButton';
import SpinIndicador from '../../components/helpers/SpinIndicador';
import { AiOutlineSearch } from 'react-icons/ai';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

import { useQueryClient } from '@tanstack/react-query';
import { search_members, search_prospects } from '../../api/search';
import { useAuth } from '../../context/AuthProvider';
import { t } from 'i18next';
import RefreshSvg from '../../assets/icons/RefreshSvg';
export default function HeaderClasses({
  title,
  isCdp,
  handleSelectMember,
  day_selected,
  setday_selected,
  day_selected_trial,
  setday_selected_trial,
  classes,
  isLoadingClases,
  hour_selected,
  sethour_selected,
  class_selected,
  setclass_selected,
  trialClassesSlots,
  isLoadingTrialClassesSlots,
  settrial_date_slots,
  trial_date_slots,
  class_selected_trial,
  setclass_selected_trial,
  hour_selected_trial,
  sethour_selected_trial,
  handleSelectProspect,
}) {
  const queryClient = useQueryClient();
  const datepickerRef = useRef(null);
  const datepickerCDPRef = useRef(null);
  const { user } = useAuth();
  const [isAdding, setIsAdding] = useState(false);
  const searchContainerRef = useRef(null);
  const searchRef = useRef(null);
  const [searchMember, setsearchMember] = useState('');
  const [suggestionList, setsuggestionList] = useState([]);
  const [prospectsList, setprospectsList] = useState([]);
  const [loadingSuggestions, setloadingSuggestions] = useState(false);
  const [loadingProspects, setloadingProspects] = useState(false);
  const [arr_hours, setarr_hours] = useState([]);
  const [arr_classes, setarr_classes] = useState([]);
  const [arr_hours_trials, setarr_hours_trials] = useState([]);
  const [arr_classes_trials, setarr_classes_trials] = useState([]);
  const [debouncedSearch, setDebouncedSearch] = useState(searchMember);

  const transformSlots = (slots, timeKey, labelKey) => {
    const hoursMap = new Map();
    const classesMap = new Map();
    
    slots.forEach((item) => {
      if (!hoursMap.has(item[timeKey])) {
        hoursMap.set(item[timeKey], {
          label: dayjs(item[timeKey], 'YYYY-MM-DD HH:mm:ss').format('HH:mm'),
          value: item[timeKey],
          id: item.id,
        });
      }
      if (!classesMap.has(item.id)) {
        classesMap.set(item.id, {
          label: item[labelKey],
          value: item.id,
        });
      }
    });

    return {
      hours: [...hoursMap.values()],
      classes: [...classesMap.values()],
    };
  };

  useEffect(() => {
    if (!isLoadingTrialClassesSlots && trialClassesSlots?.length > 0) {
      const { hours, classes } = transformSlots(
        trialClassesSlots,
        'start',
        'gym_class_name'
      );
      setarr_hours_trials(hours);
      setarr_classes_trials(classes);
    }
  }, [isLoadingTrialClassesSlots, trialClassesSlots]);

  useEffect(() => {
    if (!isLoadingClases && classes.length > 0) {
      const { hours, classes: classList } = transformSlots(
        classes,
        'start',
        'gym_class_name',
        
      );
      setarr_hours(hours);
      setarr_classes(classList);
    }
  }, [isLoadingClases, classes]);
  const setSelectedTime = (
    hours,
    classesArray,
    setSelectedHour,
    setSelectedClass,
    hourSelected,
    isCdp = false
  ) => {
    const horaActual = dayjs().tz(user.country.time_zone).
    format('HH:mm');
   
    if (hours.length > 0 && !hourSelected) {
      setSelectedHour(
        hours.find(
          (item) =>
            horaActual >= item.label &&
            horaActual <
              dayjs(item.label, 'HH:mm').add(60, 'minutes').format('HH:mm')
        )?.value
      );
    }
    if (classesArray.length > 0) {
      if(isCdp){
        sethour_selected_trial(
          hours.find(
            (item) =>
              horaActual >= item.label &&
              horaActual <
                dayjs(item.label, 'HH:mm').add(60, 'minutes').format('HH:mm')
          )?.value
        );
      }
      if(!class_selected){
    const biggClass = classesArray.find((item) =>
        item.label?.toLowerCase().includes('bigg class')
      );
      if (biggClass) {
        if(isCdp){
          setclass_selected_trial(biggClass.value);
        }
        setSelectedClass(biggClass.value);
      }
      }
    if(hourSelected && !hours?.some((item) => item.value == hourSelected)){
    sethour_selected(hours[0]?.value || null)
    // invalidateMainClasses()
    }
  }
  };
  useEffect(() => {
    setSelectedTime(
      arr_hours,
      arr_classes,
      sethour_selected,
      setclass_selected,
      hour_selected
    );
  }, [arr_hours, arr_classes, hour_selected]);

  useEffect(() => {
    setSelectedTime(
      arr_hours_trials,
      arr_classes_trials,
      sethour_selected_trial,
      setclass_selected_trial,
      hour_selected_trial,
      true
    );
  }, [arr_hours_trials,arr_classes_trials,day_selected]);
  useEffect(() => {
    let hours =   Object.values(
      (classes || []).reduce((acc, item) => {
        if (
          !acc[item.start] &&
          item.id == class_selected_trial &&
          dayjs(item.start).format('YYYY-MM-DD') ==
            dayjs(day_selected).format('YYYY-MM-DD')
        ) {
          acc[item.start] = {
            label: dayjs(item.start).tz(user.country.time_zone).format('HH:mm'),
            value: item.start,
          };
        }
        return acc;
      }, {})
    ) || []
    setarr_hours_trials(
      hours
    );
  }, [day_selected_trial,classes,class_selected_trial]);
  // await ()
  const apiGetProspects = async () => {
    const prospects = await search_prospects(searchMember);
    let results = prospects?.results.filter((m)=>m.last_subscription_member == null)
    setprospectsList(results);
  };
  const apiGetMembers = async () => {
    // setloadingSuggestions(true);
    // const members = await getMembersPopulate();
    const members = await search_members(searchMember);
    // setloadingSuggestions(false);
    setsuggestionList(members.results);
  };
  const handleAddMember = () => {
    if (!isAdding) {
      searchRef.current.focus();
    } else {
      searchRef.current.blur();
    }
    searchRef.current.focus();

    setIsAdding(!isAdding);
  };
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchMember);
    }, 300); // 300 ms de debounce time
  
    return () => {
      clearTimeout(handler);
    };
  }, [searchMember]);
  useEffect(() => {
    if (debouncedSearch.trim().length < 2) {
      // Si la cadena de búsqueda tiene menos de 2 caracteres, no hacer nada.
      return;
    }
    if (isCdp) {
       apiGetProspects();
    } else {
      apiGetMembers();

    }
  }, [debouncedSearch]);
  
  const invalidateMainClasses = () => {
    queryClient.invalidateQueries({
      queryKey: ['day_reserves', dayjs(hour_selected).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')],
    });
  };
  const invalidateMainClassesTrial = () => {
    queryClient.invalidateQueries({
      queryKey: [
        'day_reserves_trial',
        dayjs(hour_selected_trial).format('YYYY-MM-DD')      ],
    });
  };
  useEffect(() => {
   if(searchMember.length === 0){
    if(isCdp){
      setprospectsList([])
    }else {
      setsuggestionList([])

    }
   }
  }, [searchMember])
  
  return (
    <div
      className={`
    ${isAdding && !isCdp && 'max-h-[301px] sm:max-h-[207px]'} 
     ${!isAdding && !isCdp ? 'max-h-[232px] sm:max-h-[138px]' : ''} 
     ${isCdp && 'cdp-header '}
      ${isCdp && isAdding ? 'max-h-[415px] sm:max-h-[400px]' : ''}
      ${isCdp && !isAdding ? 'max-h-[144px] sm:max-h-[136px]' : ''}
       bg-lightGreen  rounded-lg p-5 rounded-b-none smooth-t `}
    >
      <div className='flex flex-row justify-between'>
        <h1 className='text-black font-bold text-2xl font-MessinaSansSemiBold'>
          {title}
        </h1>
        <div className='flex  flex-row items-center '>
       
          { dayjs( isCdp ? day_selected_trial: day_selected).diff(dayjs(), 'days') >= 0 &&
            <AddButton
            title={t('book_class','Agendar Clase')}
            greenColor={false}
            onClick={() => handleAddMember()}
          />}
        </div>
      </div>
      <div className='mt-5 column-mobile class-data'>
        <ConfigProvider 
       theme={{
        token:{
          cellHoverBg:'red!important',
          hoverBg:'red!important',
        }
       }}
        locale={es_ES}>
          <DatePicker
            ref={datepickerRef}
            allowClear={false}
            defaultPickerValue={day_selected} //using dayjs
            onChange={(date, dateString) => {
              datepickerRef.current.blur();
              if (isCdp) {
                return setday_selected_trial(date);
              }        
              setday_selected(date);
              setday_selected_trial(date);
              // invalidateMainClassesTrial();

            }}
            defaultValue={isCdp ? day_selected_trial : day_selected}
            showToday
            // format={'YYYY-MM-DD'}
            className='shadow-none bg-darkGreen outline-none border-none py-2  focus-within:bg-darkGreen hover:bg-darkGreen focus:bg-darkGreen'
            />
          {isCdp && (
            <button
              className='pt-3 pl-3 hidden lg:inline-block'
              onClick={() => invalidateMainClassesTrial()}
            >
              {isLoadingTrialClassesSlots ? (
                <SpinIndicador />
              ) : (
                <RefreshSvg color='#111' />
              )}
            </button>
          )}
          {!isCdp && (
            <Space wrap>
              <Select
                defaultValue={class_selected}
                value={class_selected}
                placeholder={t('gym_classes.title_singular', 'Clase')}
                onChange={(value, option) => {
                  setclass_selected(value);
                  let hours =   Object.values(
                    (classes || []).reduce((acc, item) => {
                      if (
                        !acc[item.start] &&
                        item.id == value &&
                        dayjs(item.start).format('YYYY-MM-DD') ==
                          dayjs(day_selected).format('YYYY-MM-DD')
                      ) {
                        acc[item.start] = {
                          label: dayjs(item.start).tz(user.country.time_zone).format('HH:mm'),
                          value: item.start,
                          id: item.id,
                        };
                      }
                      return acc;
                    }, {})
                  ) || []
                  setarr_hours(
                    hours
                  );
                  if(hours.length > 0){
                    sethour_selected(hours.find(
                      (item) =>
                      item.value == hour_selected
                    )?.value || hours[0].value)

                  }

                  // invalidateMainClasses();
                }}
              variant='borderless'
                className='bg-darkGreen text-white outline-none  border-none  rounded-md ml-3 font-DrukWideMedium py-1'
                style={{ width: 200 }}
                // disabled={!isLoadingClases && classes.length === 0}
                loading={isLoadingClases}
                options={arr_classes}
              />
              <Select
                value={hour_selected}
                onChange={(value) => {
                  sethour_selected(value);
                }}
                placeholder={t('time_slots.title_singular', 'Horario')}
                variant='borderless'
                className='bg-darkGreen text-white outline-none border-none  rounded-md ml-3 font-DrukWideMedium py-1 '
                style={{
                  width: 120,
                }}
                loading={isLoadingClases}
                options={arr_hours.sort((a, b) => dayjs(a.value).diff(b.value)).filter((item) => item.id == class_selected)}
              />
             
            </Space>
          )}
        </ConfigProvider>
      </div>

      <div
        ref={searchContainerRef}
        className={`${
          !isAdding && 'opacity-0 pointer-events-none'
        } rounded-md top-0  z-40 w-full smooth-t mt-4 add-to-class-button relative`}
      >
        {isCdp && (
          <div className='mb-3 column-mobile class-data is-cdp'>
            <h2 className='text-black mb-2'>
              {t('reserve_cdp.title','Seleccioná la fecha, la clase y la hora para agendar la clase de prueba')}
              
            </h2>
            <ConfigProvider
             theme={{
              token:{
                cellHoverBg:'red!important',
                hoverBg:'red!important',
              }
             }}
            locale={es_ES}>
              <DatePicker
                ref={datepickerCDPRef}
                allowClear={false}
                value={trial_date_slots}
                placeholder={t('select_date','Seleccioná una fecha')}
                showToday
                onChange={(date, dateString) => {
                  // return console.log('date',date);
                  datepickerCDPRef.current.blur();
                  setday_selected(date);
                  settrial_date_slots(date);
                  invalidateMainClassesTrial();
                  // setday_selected_trial(date);
                }}
                className='shadow-none bg-darkGreen outline-none border-none py-2  focus-within:bg-darkGreen hover:bg-darkGreen focus:bg-darkGreen'
              />

              <Space wrap>
                <Select
                  loading={isLoadingTrialClassesSlots}
                  placeholder={t('select_class','Seleccioná una clase')}
                  bordered={false}
                  className='bg-darkGreen text-white outline-none border-none  rounded-md ml-3 font-DrukWideMedium py-1 '
                  style={{
                    width: 130,
                  }}
                  value={class_selected_trial}
                  options={arr_classes_trials}
                  onChange={(value, option) => {
                    setclass_selected_trial(value);

                  
                  }}
                />
                <Select
                  loading={isLoadingTrialClassesSlots}
                  placeholder={t('select_hour','Seleccioná un horario')}
                  value={hour_selected_trial}
                  // defaultValue='BIGG CLASS'
                  bordered={false}
                  className='bg-darkGreen text-white outline-none  border-none  rounded-md ml-3 font-DrukWideMedium py-1'
                  style={{
                    width: 130,
                  }}
                  onChange={(value) => {
                    sethour_selected_trial(value);
                  }}
                  options={arr_hours_trials.sort((a, b) =>
                    dayjs(a.value).diff(b.value)
                  )}
                />
              </Space>
            </ConfigProvider>
          </div>
        )}
        <div className='flex items-center gap-3 sticky z-50 top-0 bg-white rounded-3xl w-full border-darkGreen border-[1px] p-2'>
          <div className='w-[32px] h-[32px] rounded-full bg-darkGreen flex ml-1'>
            <AiOutlineSearch
              size={18}
              className='cursor-pointer hover:scale-110 m-auto'
            />
          </div>

          <input
            // onSe
            ref={searchRef}
            placeholder={`${t('header.start_search','Empezá a escribir para buscar')}...`}
            className='outline-none smooth-t w-full  !text-black pl-2 pr-5 !placeholder-gray-500 '
            autoFocus
            onPaste={(e) => setsearchMember(e.target.value)}
            onChange={(e) => setsearchMember(e.target.value)}
            value={searchMember}
            type='search'
          />

          {(isCdp ? loadingProspects : loadingSuggestions) && <SpinIndicador />}
        </div>
        <div className='absolute top-[100%] left-0 w-full bg-white z-40 max-h-[20rem] overflow-y-scroll px-2 rounded-b-md'>
          {(isCdp
            ? prospectsList
            : suggestionList).map((item) => (
            <div
              key={item.id}
              className='hover:cursor-pointer py-2 my-1 px-2 searchbox-item smooth-t w-full rounded-md text-black'
              onClick={() => {
                console.log('item',item);
                setsearchMember('');
                setIsAdding(false);
                if (isCdp) {
                  return handleSelectProspect(item);
                }
                handleSelectMember(item);
              }}
            >
              <span>{item.first_name + ' '+item.last_name} |</span>
              <span> N° {item.id}</span>
              <span> | {item.location_name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
