import React, {  useEffect } from 'react';
import {  Skeleton } from 'antd';
import SelectCoach from '../helpers/SelectCoach';
import {
  canUpdateMemberCoach,
} from '../../services/can_user';
import { t } from 'i18next';
import HeaderPersonalData from './HeaderPersonalData';
import ProspectPersonalData from './ProspectPersonalData';
import UserPersonalData from './UserPersonalData';

export default function PersonalData({
  FORM_MEMBER,
  isLoading,
  member,
  setFORM_MEMBER,
  setmodalSale,
  isProspect,
  openNotification,
  dischargeReasons,
  isLoadingDischarge,
  document_types,isLoadingDocument_types,
  customer_type
}) {
  useEffect(() => {
    if (!isLoading) {
      setFORM_MEMBER({
        ...member,
        referrer_id: member?.referrer_name,
        prospect_coach_id: member?.prospect?.coach_id,
      });
    }
  }, [isLoading]);

  return (
    <div className='w-[100%] lg:w-[30%] flex flex-column'>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <HeaderPersonalData
          member={member}
          customer_type={customer_type}
          isProspect={isProspect}
          setmodalSale={setmodalSale}
          openNotification={openNotification}
        />
      )}
      {customer_type !== 'Lead' &&<SelectCoach
      isLoading={isLoading}
      initialCoach={member?.coach}
        isProspect={isProspect}
        disabled={!canUpdateMemberCoach()}
        coach_location={member?.location_id}
        value={FORM_MEMBER['coach_id']}
        setValue={(newValue, option) => {
          setFORM_MEMBER({
            ...FORM_MEMBER,
            coach_id: newValue,
            coach: {
              name: option?.label,
              coach_user_id: option?.coach_user_id,
            },
          });
        }}
      />}
      {/* PROSPECT INFO */}
      {customer_type !== 'Lead' &&
      <ProspectPersonalData
        isProspect={isProspect}
        member={member}
        isLoading={isLoading}
        FORM_MEMBER={FORM_MEMBER}
        setFORM_MEMBER={setFORM_MEMBER}
        dischargeReasons={dischargeReasons}
        isLoadingDischarge={isLoadingDischarge}
      />
      }

      {/* PERSONAL INFO */}
    <UserPersonalData 
    isLoading={isLoading}
    FORM_MEMBER={FORM_MEMBER}
    document_types={document_types}
    isLoadingDocument_types={isLoadingDocument_types}
    setFORM_MEMBER={setFORM_MEMBER}
    customer_type={customer_type}
    />
      {customer_type !== 'Lead' &&<div className='mt-10'>
        <h3 className='font-MessinaSansSemiBold text-xl pb-4'>
          {t('general_observations', 'Observaciones generales')}
        </h3>
        <textarea
          value={FORM_MEMBER['observation'] ? FORM_MEMBER['observation'] : ''}
          onChange={(e) => {
            setFORM_MEMBER({
              ...FORM_MEMBER,
              observation: e.target.value,
            });
          }}
          placeholder={t('general_observations', 'Observaciones generales')}
          className=' bg-primaryDark outline-none w-full rounded-lg h-32 resize-none p-4'
        ></textarea>
      </div>}
    </div>
  );
}
