// Páginas principales
import Dashboard from '../../pages/Dashboard';
import Members from '../../pages/Members';
import Reports from '../../pages/Reports';
import Login from '../../pages/Login';
import Prospects from '../../pages/Prospects';
import Leads from '../../pages/Leads';
import CalendarClasses from '../../pages/CalendarClasses';
import CalendarCoaches from '../../pages/CalendarCoaches';
import Products from '../../pages/Products';
import Users from '../../pages/Users';
import Consumables from '../../pages/Consumables';
import Discounts from '../../pages/Discounts';
import Sales from '../../pages/Sales';
import Gym_classes from '../../pages/Gym_classes';
import ProductSessions from '../../pages/ProductSessions';
import Memberships from '../../pages/Memberships';
import Coaches from '../../pages/Coaches';
import Payments from '../../pages/Payments';
import Transactions from '../../pages/Transactions';
import Balances from '../../pages/Balances';
import ProductStocks from '../../pages/ProductStocks';
import TimeSlots from '../../pages/TimeSlots';
import RegionConfigTypes from '../../pages/RegionConfigTypes';
import Locations from '../../pages/Locations';
import Countries from '../../pages/Countries';
import SalesGoalsTypes from '../../pages/SalesGoalsTypes';
import SalesGoals from '../../pages/SalesGoals';
import ClientMpUpload from '../../pages/ClientMpUpload';
// import Test from '../../pages/ClientMpUpload'; // Este parece duplicado
import NotFound from '../../pages/NotFound';
import { queryClient } from './queryClient';

// Layout y configuración del router
import Layout from '../../layout/Layout';
import { rootLoader } from '../loaders/rootLoader';

// Loaders específicos
import {
  ConsumableLoader,
  DiscountsLoader,
  MembershipLoader,
  ProductLoader,
  SessionsLoader,
} from '../loaders/ProductsLoaders';
import { UsersLoader } from '../loaders/UsersLoaders';
import {
  StockLoader,
  TransactionsLoader,
} from '../loaders/AccountingLoaders';
import {
  ClassesLoader,
  TimeSlotsLoader,
} from '../loaders/ClassesLoaders';
import { RegionConfigLoader } from '../loaders/ConfigLoaders';
export const routes = [
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/',
    element: <Layout />,
    loader: rootLoader(queryClient),
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: '/members',
        element: <Members />,
      },
      {
        path: '/prospects',
        element: <Prospects />,
      },
      {
        path: '/leads',
        element: <Leads />,
      },
      {
        path: '/users',
        element: <Users />,
        loader: UsersLoader(queryClient),
      },
      {
        path: '/calendar',
        element: <CalendarClasses />,
      },
      {
        path: '/products',
        element: <Products />,
        loader: ProductLoader(queryClient),
      },
      {
        path: '/consumables',
        element: <Consumables />,
        loader: ConsumableLoader(queryClient),
      },
      {
        path: '/memberships',
        element: <Memberships />,
        loader: MembershipLoader(queryClient),
      },
      {
        path: '/discounts',
        element: <Discounts />,
        loader: DiscountsLoader(queryClient),
      },

      {
        path: '/sessions',
        element: <ProductSessions />,
        loader: SessionsLoader(queryClient),
      },
      {
        path: '/gym_classes',
        element: <Gym_classes />,
        loader: ClassesLoader(queryClient),
      },
      {
        path: '/time_slots',
        element: <TimeSlots />,
        loader: TimeSlotsLoader(queryClient),
      },
      {
        path: '/sales',
        element: <Sales />,
        // loader: SalesLoader(queryClient),
      },
      {
        path: '/payments',
        element: <Payments />,
        // loader: PaymentsLoader(queryClient),
      },
      {
        path: '/transactions',
        element: <Transactions />,
        loader: TransactionsLoader(queryClient),
      },
      {
        path: '/balances',
        element: <Balances />,
        // loader: BalanceLoader(queryClient),
        // loader: async () => {
        //   return redirect('https://eye.bigg.fit/es/balances');

        // }
      },
      {
        path: '/product_location_stocks',
        element: <ProductStocks />,
        loader: StockLoader(queryClient),
      },
      {
        path: '/coaches',
        element: <Coaches />,
      },
      {
        path: '/calendar_coaches',
        element: <CalendarCoaches />,
      },
      {
        path: '/reports',
        element: <Reports />,
      },
      {
        path: '/locations',
        element: <Locations />,
      },
      {
        path: '/countries',
        element: <Countries />,
      },
      {
        path: '/region_config_types',
        element: <RegionConfigTypes />,
        loader: RegionConfigLoader(queryClient),
      },
      {
        path: '/sales_goals_types',
        element: <SalesGoalsTypes />,
      },
      {
        path: '/sales_goals',
        element: <SalesGoals />,
      },

      {
        path: '/help_center',
        loader: async () => {
          window.open('https://biggeye.kb.help', '_blank');
          return redirect(window.location.href);
        },
      },
    ],
  },
  {
    path: '/mp',
    element: <ClientMpUpload />,
  },
];
