import React, { useState, useEffect } from 'react';
import { AiOutlineDown } from 'react-icons/ai';
import MainClasses from '../components/Desktop/MainClasses';
import ToDoList from '../components/ToDoList';
import DailyOperations from '../components/Desktop/DailyOperations';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';
import SalesIndicator from '../components/charts/SalesChart';
import ActiveMembersChart from '../components/charts/ActiveMembersChart';
import NewAccountsChart from '../components/charts/NewAccountsChart';
import RenovationChart from '../components/charts/RenovationChart';
import CRMChart from '../components/charts/CRMChart';
import { useTranslation,  } from 'react-i18next';
import Pxq from '../components/charts/pxQ';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { BsChevronDown } from 'react-icons/bs';

import ProspectsUncontacted from '../components/Desktop/ProspectsUncontacted';
import { useGlobalContext } from '../context/GlobalState';
import ConvertionCdp from '../components/charts/ConvertionCdp';
import ConvertionLeadToCdp from '../components/charts/ConvertionLeadToCdp';
import Convertion2b from '../components/charts/Convertion2b';
import RatingCoachesChart from '../components/charts/RatingCoachesChart';
import RatingSellersChart from '../components/charts/RatingSellersChart';
import { useCdpConvertion, useConvertion2b, useGetActiveMembers, useGetActiveMembersEvolution, useGetSalesChart, useLeadToCdpConvertion } from '../api/charts';
import AssignmentsChart from '../components/charts/AssignmentsChart';
import { Dropdown } from 'antd';
import { isUserSuperAdmin } from '../services/can_user';
import LeadsChatbot from '../components/LeadsChatbot';
// import * as contentful from "contentful"
// import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

dayjs.locale('es');
const CURRENT_MONTH = dayjs().format('MMMM');
export default function Dashboard() {
  const { t, i18n } = useTranslation();
  const [entry, setentry] = useState(false)
  // const client = contentful.createClient({
  //   // This is the space ID. A space is like a project folder in Contentful terms
  //   space: '0a663jk7aiai',
  //   // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
  //   accessToken: 'mF72qm6-0_pE1j7Q1-TdzJshIJWn5Fm5R11DiAd0lqk',
  // })
  // // This API call will request an entry with the specified ID from the space defined at the top, using a space-specific access token
  // client
  //   .getEntries(
  //     {
  //       content_type:'newsletter',
  //       // select:'fields',
  //       'metadata.tags.sys.id[in]': 'owners', // Filtra las entradas que tienen el tag 'Admin'
  //     }
  //   )
  //   .then((entry) => {
  //     console.log('entry',entry);
      
  //     setentry(entry)
  //   })
  //   .catch((err) => console.log(err))
  const location = useLocation();
  const [
    modalMemberOpen,
    setModalMemberOpen,
    setmodalSale,
    setmodalTransaction,
    setTypeMemberCreate,
    setOpenCRM,
    openCRM,
    handleOpenReturn,
  ] = useOutletContext();
  const { user } = useAuth();
  const { state } = useGlobalContext();
  
  if (!user) return null;
if(!state) return null;
console.log('state',user);

  const [openStats, setOpenStats] = useState(true);
  const [selectedView, setselectedView] = useState('General')
  const handleOpenStats = (e) => {
    
    setOpenStats((openStats) => !openStats);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const [lngs, setLngs] = useState({});

  useEffect(() => {
    i18n.services.backendConnector.backend.getLanguages((err, ret) => {
      if (err) return; // TODO: handle err...
      setLngs(ret);
    });
  }, []);
  
  const { data: cdp_convertion, isLoading:loadingcdp_convertion} = useCdpConvertion({enabled:!openStats }); // state?.user?.roles?.find((r)=>r.id == 7)
  const { data: convertion, isLoading:loadingConvertion } = useConvertion2b({enabled:!openStats }); //
  const { data: lead_to_cdp_convertion, isLoading:loadingConvertionCDP } = useLeadToCdpConvertion({enabled:!openStats}); //
  const { data: data_sales, isLoading:isLoadingSales } = useGetSalesChart({enabled:!openStats  }); //
const {data:members,isLoading} = useGetActiveMembers({enabled:!openStats}); //
const {data:evolutionMembers,isLoading:isLoadingEvolution} = useGetActiveMembersEvolution({enabled:!openStats}); //
  return (
    <div className='w-full pb-12 px-5 md:p-5 md:pl-0 md:ml-10'>
      <div className='md:mr-10 relative flex flex-column mt-5'>
      <div
  className='pb-5 flex jic cursor-pointer group'
  onClick={handleOpenStats}
>
  <p className='group-hover:underline transition duration-500 ease-in-out'>
    {t('dashboard.title_charts')} {CURRENT_MONTH}
  </p>
 {[260,2027,306,226].includes(user.user_id)&& <div className={` items-center gap-2 ${!openStats ? 'flex flex-row':'hidden'} `}>
    <p>Conversion:</p>
    <Dropdown
      menu={{
        items: [
          { key: 'General', label: 'General' },
          { key: 'Por Canal', label: 'Por Canal' },
        ],
        selectable: true,
        defaultSelectedKeys: [selectedView],
        onClick: ({ key, domEvent }) => {
          domEvent.stopPropagation(); // Evitar la propagación del evento al hacer clic en el menú
          setselectedView(key);
        },
    
      }}
      trigger={['click']}
      onOpenChange={(e) => {
        console.log(e);
      }}
    >
      <div
        onClick={(e) => {
          e.stopPropagation(); // Evita la propagación del evento de clic al contenedor padre
        }}
        className=' border-2 border-primaryGrey hover:opacity-80 ease-in-out duration-300 rounded-sm py-1 px-2 flex flex-row items-center'
      >
        {selectedView}
        <BsChevronDown className='ml-2' />
      </div>
    </Dropdown>
  </div>}
  <AiOutlineDown
    className={`${openStats && 'transform -rotate-90'} smooth-t`}
  />
</div>
       <div
          className={`${
            openStats && 'closed'
          } pb-5 mr-5 w-full border-b-2 border-b-primaryGrey stats-container smooth-t`}
        >
           {!openStats &&
          <>
          <div className='w-full overflow-hidden lg:overflow-visible gap-4 grid lg:grid-cols-6 grid-cols-1'>
            <SalesIndicator chart={data_sales}/>
            <Convertion2b chart={convertion} view={selectedView} />
            {/* <TrafficIndicator /> */}
            <ActiveMembersChart 
            members={members}
            evolutionMembers={evolutionMembers}
            />
          </div>
          <div className=' w-full gap-4 grid lg:grid-cols-6  grid-cols-1 pt-5'>
            {/* TASA DE USO */}

            <Pxq />

            <ConvertionLeadToCdp view={selectedView} />
            {/* Altas */}

            <RatingSellersChart 
            lead_to_cdp_convertion={lead_to_cdp_convertion}
            convertion={convertion}/>
            {/* <TicketAVGChart /> */}
          </div>
          <>
            <div className=' w-full gap-4 grid lg:grid-cols-6  grid-cols-2 pt-5'>
              <NewAccountsChart />
              <RenovationChart />
              <ConvertionCdp  chart={cdp_convertion} view={selectedView}/>
              <RatingCoachesChart chart={cdp_convertion}/>
            </div>
            <div className=' w-full gap-4 grid lg:grid-cols-6  grid-cols-1 pt-5'>
              <CRMChart />
              <AssignmentsChart />
            </div>
          </>
          </> }
        </div>
        <div className='mt-6 mb-2 flex column-mobile dashboard-sub-header'>
          {/* <button  onClick={handleStartAudio} >sonar sonido</button> */}

          <ProspectsUncontacted />
        </div>
        <div className='pt-6 flex flex-col  lg:flex-row justify-between gap-x-6 classes-container'>
         
    
          <MainClasses
            title={t('dashboard.title_classes')}
            large
            isCdp={false}
            setModalMemberOpen={setModalMemberOpen}
          />
          <MainClasses
            title={t('dashboard.title_cdp_classes')}
            large={false}
            isCdp
            setModalMemberOpen={setModalMemberOpen}
          />
        </div>
        <div className='pt-8 flex flex-col  lg:flex-row justify-between gap-x-6 '>
         {/* <MPCardHolder /> */}
          <ToDoList
            visible={openCRM.visible}
            setOpenCRM={(visible, type, start, end) =>
              setOpenCRM({ visible, type, start, end })
            }
            setModalMemberOpen={setModalMemberOpen}
            customer_id={null}
            styles={`lg:w-[58%] `}
          />
          <DailyOperations
            showExtract
            setModalMemberOpen={setModalMemberOpen}
            setmodalTransaction={setmodalTransaction}
            setmodalSale={setmodalSale}
            styles={`mt-10 lg:mt-0  lg:w-[42%] `}
            handleOpenReturn={handleOpenReturn}
          />
        </div>
        {/* {entry &&<div className=''>{
          documentToReactComponents(entry.items[0].fields.body)}</div>} */}
       {/* {  isUserSuperAdmin() &&<div className='pt-8 flex flex-col  lg:flex-row justify-between gap-x-6 '>
        <LeadsChatbot 
        setModalMemberOpen={setModalMemberOpen}
        />
</div>} */}
      </div>
    </div>
  );
}
