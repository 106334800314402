import { useQuery } from '@tanstack/react-query';
import { getLocationId } from '../services/utils/getLocation';
import { fetchData } from '../services/utils/axiosInstance';
import { formatUTC } from '../services/utils/formatUTC';

export const getCalendarClasses = async (start, end) => {
  const location_id = getLocationId();
  const result = await fetchData(
    `/calendar?location_id=${location_id}&start=${start}&end=${end}`
  );
  return result;
};
export const useGetCalendarClasses = (start, end) => {
  const location_id = getLocationId();
  if (!start || !end) return { data: [] };
  return useQuery({
    queryKey: ['calendar', start, end, location_id],
    queryFn: async () => getCalendarClasses(start, end),
    select: (data) => data.sort((a, b) => a.id - b.id),
  });
};

export const getCalendarCoaches = async (start, end) => {
  const location_id = getLocationId();
  return fetchData(
    `/calendar_week?location_id=${location_id}&start=${start}&end=${end}`
  );
};
export const useGetCalendarCoaches = (start, end) => {
  return useQuery({
    queryKey: ['calendar_week', start, end],
    queryFn: async () => getCalendarCoaches(start, end),
    // select: (data) =>
    //   data.map((item) => ({
    //     ...item,
    //     start: formatUTC(item.start),
    //   })),
  });
};
export const updateCoachClass = async (data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/coach_gym_classes?location_id=${location_id}`,
    'POST',
    data
  );
};
export const getCoachClass = async (gym_class_id, scheduled_at, coach_id) => {
  const location_id = getLocationId();

  return await fetchData(
    `/coach_gym_classes?location_id=${location_id}&gym_class_id=${gym_class_id}&scheduled_at=${scheduled_at}&coach_id=${coach_id}`
  );
};
export const updateCoachClassStatus = async (id, data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/coach_gym_classes/${id}?location_id=${location_id}`,
    'PATCH',
    data
  );
};
export const CreateCoachClass = async (data) => {
  const location_id = getLocationId();

  return await fetchData(`/coach_gym_classes`, 'POST', data);
};
export const DeleteCoachClass = async (id) => {
  const location_id = getLocationId();
  return await fetchData(
    `/coach_gym_classes/${id}?location_id=${location_id}`,
    'DELETE'
  );
};
