import React, { useEffect, useState, useMemo } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from 'recharts';
import { AiOutlineUser } from 'react-icons/ai';
import Card from './Card';
import ActiveMembersTooltip from './utils/ActiveMembersTooltip';
import PopActiveMembers from '../helpers/popovers/PopActiveMembers';
import dayjs from 'dayjs';
import { t } from 'i18next';

export default function ActiveMembersChart({ members, evolutionMembers }) {
  const first_month = useMemo(() => {
    if (evolutionMembers?.results) {
      let first = evolutionMembers.results.sort((a, b) => {
        const dateA = dayjs(a.date_analize);
        const dateB = dayjs(b.date_analize);

        if (dateA.isBefore(dateB)) {
          return -1;
        }
        if (dateA.isAfter(dateB)) {
          return 1;
        }
        return 0;
      });
      if (first && first.length > 0) {
        const date = dayjs(first[0].date_analize).format('MMMM');
        return date.charAt(0).toUpperCase() + date.slice(1);
      }
      return false;
    }
    return false;
  }, [evolutionMembers]);
  const last_month = useMemo(() => {
    if (evolutionMembers?.results) {
      let last = evolutionMembers.results.sort((a, b) => {
        const dateA = dayjs(a.date_analize);
        const dateB = dayjs(b.date_analize);

        if (dateA.isBefore(dateB)) {
          return 1;
        }
        if (dateA.isAfter(dateB)) {
          return -1;
        }
        return 0;
      });
      if (last && last.length > 0) {
        const date = dayjs(last[0].date_analize).format('MMMM');
        return date.charAt(0).toUpperCase() + date.slice(1);
      }
      return false;
    }
    return false;
  }, [evolutionMembers]);
  const transformData = (data) => {
    const groupedByDate = {};

    data.results
      .sort((a, b) => {
        const dateA = dayjs(a.date_analize);
        const dateB = dayjs(b.date_analize);

        if (dateA.isBefore(dateB)) {
          return -1;
        }
        if (dateA.isAfter(dateB)) {
          return 1;
        }
        return 0;
      })
      .forEach((item) => {
        const { date_analize, category, members } = item;

        if (!groupedByDate[date_analize]) {
          groupedByDate[date_analize] = { date: date_analize };
        }

        groupedByDate[date_analize][category] =
          (groupedByDate[date_analize][category] || 0) + members;
      });
    return Object.values(groupedByDate);
  };

  const formattedData = useMemo(() => {
    if (evolutionMembers) {
      return transformData(evolutionMembers);
    }
    return [];
  }, [evolutionMembers]);
  const this_month_sum = useMemo(() => {
    if (formattedData && formattedData.length > 0) {
      return Object.values(formattedData[formattedData.length - 1])
        .slice(1)
        .filter((item) => typeof item === 'number')
        .reduce((acc, item) => acc + item, 0);
    }
    return false;
  }, [formattedData]);
  const last_month_sum = useMemo(() => {
    if (formattedData && formattedData.length > 1) {
      return Object.values(formattedData[formattedData.length - 2])
        .slice(1)
        .filter((item) => typeof item === 'number')
        .reduce((acc, item) => acc + item, 0);
    }
    return false;
  }, [formattedData]);
  const percentage = useMemo(() => {
    if (this_month_sum && last_month_sum) {
      let result = ((this_month_sum - last_month_sum) / last_month_sum) * 100;
      if (result < 1) {
        return result.toFixed(1);
      } else {
        return result.toFixed(0);
      }
    }
    return false;
  }, [this_month_sum, last_month_sum]);
  return (
    <Card
      description={members?.description?.description}
      icon={<AiOutlineUser />}
      title={t('dashboard.activeusers_title')}
      Extraclass={`col-span-2`}
      multipleInsights={members?.results}
      percentage={percentage}
      description_last_month={
        last_month_sum +
        ' ' +
        t(
          'dashboard.active_description_last_month',
          'socios activos pagos en el mes pasado'
        )
      }
      popover={(index) =>
        PopActiveMembers({ data: members?.results, member_type: index })
      }
      size='large'
    >
      <AreaChart
        data={formattedData}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: -5,
        }}
      >
        <Tooltip content={<ActiveMembersTooltip />} />
        {evolutionMembers && evolutionMembers.length > 0 && (
          <XAxis dataKey='value' tickSize={0}>
            <Label
              value={first_month ? first_month : ''}
              position='insideLeft'
              className='text-white'
            />
            <Label
              value={last_month ? last_month : ''}
              position='insideRight'
              className=' text-white'
            />
          </XAxis>
        )}
        <XAxis dataKey='date' />
        <YAxis />
        <Area
          type='monotone'
          dataKey='8 or more'
          stackId='1'
          stroke='#1EA05A'
          fill='#1EA05A'
        />
        <Area
          type='monotone'
          dataKey='7 uses'
          stackId='1'
          stroke='#ADFF19'
          fill='#ADFF19'
        />

        {/* <Area type="monotone" dataKey="7 or less" stackId="1" stroke="#CEF5CE" fill="#CEF5CE" /> */}

        <Area
          type='monotone'
          dataKey='6 uses'
          stackId='1'
          stroke='#CEF3F5'
          fill='#CEF3F5'
        />

        {/* <Area type="monotone" dataKey="4 or less" stackId="1" stroke="#FFD500" fill="#FFD500" /> */}
        <Area
          type='monotone'
          dataKey='5 or less'
          stackId='1'
          stroke='#FFA500'
          fill='#FFA500'
        />

        <Area
          type='monotone'
          dataKey='no_reserve'
          stackId='1'
          stroke='#ED4C5C'
          fill='#ED4C5C'
        />

        {/* "5 or less"
"6 uses"
"7 uses"
"8 or more"
"no_reserve" */}
      </AreaChart>
    </Card>
  );
}
