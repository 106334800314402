import { fetchData } from '../services/utils/axiosInstance';
import { getLocationId } from '../services/utils/getLocation';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { compareByCreatedAt } from '../services/utils/compareByDate';
import axios from 'axios';
dayjs.extend(utc);

export const useGetAlerts = (start, end) => {
  if (!start && !end) {
    start = dayjs().format('YYYY-MM-DD');
    end = dayjs().format('YYYY-MM-DD');
  }

  return useQuery({
    queryKey: ['alerts', start, end],
    queryFn: async () => getAlerts(start, end),
  });
};
export const getAlerts = async (start, end) => {
  const location_id = getLocationId();
  return fetchData(
    `/crms?location_id=${location_id}&action_type_id=6&start=${start}&end=${end}`
  );
};
export const updateAlert = async (id, data) => {
  return fetchData(`/crms/${id}`, 'PATCH', data);
};
export const removeAlert = async (id) => {
  return fetchData(`/crms/${id}`, 'DELETE');
};
export const createMemberCrm = async (data) => {
  const location_id = getLocationId();
  return fetchData(`/crms?location_id=${location_id}`, 'POST', data);
};
export const getMemberCrm = async (id, customer_type, prospect_id) => {
  const location_id = getLocationId();
  let crm = await fetchData(
    `/crm_member?location_id=${location_id}&customer_type=${customer_type}&customer_id=${id}`
  );
  if (prospect_id) {
    const crm_prospect = await fetchData(
      `/crm_member?location_id=${location_id}&customer_type=Prospect&customer_id=${prospect_id}`
    );
    if (crm_prospect && crm_prospect.length > 0) {
      return [...crm, ...crm_prospect];
    }
  }
  return crm;
};

export const useGetMemberCrm = (id, customer_type, prospect_id) => {
  return {
    data: [],
  };
  return useQuery({
    queryKey: ['crm', id, prospect_id],
    queryFn: async () => getMemberCrm(id, customer_type, prospect_id),
    //order by date and this is de field  "sent_at": "2023-07-30T19:54:33.000Z" using moment
    // select: (data) =>
    select: (data) => data.sort((a, b) => compareByCreatedAt(a, b)),
  });
};

export const getCommentsCrm = async (crms_ids) => {
  const location_id = getLocationId();

  // Construye los parámetros de consulta dinámicamente
  const params = new URLSearchParams();
  crms_ids.forEach((id) => params.append('crms_ids[]', id));

  // Construye la URL completa con los parámetros
  const url = `https://app.bigg.fit/members/group/crm_comments?${params.toString()}`;

  // Realiza la solicitud GET con axios
  const response = await axios.get(url);
  return response.data;
};

export const getChatbotCrms = async (start, end) => {
  const location_id = getLocationId();
  return fetchData(`/crm_chatbot?location_id=${location_id}`);
};

export const useGetChatbotCrms = (start, end) => {
  return useQuery({
    queryKey: ['crm_chatbot'],
    queryFn: getChatbotCrms(start, end),
  });
};
