import React, { useEffect, useMemo } from 'react';
import Card from './Card';
import { Statistic, Tooltip } from 'antd';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import CountUp from 'react-countup';
import { useGetNewAccounts } from '../../api/charts';
import { t } from 'i18next';

export default function NewAccountsChart() {
  const { data, isLoading } = useGetNewAccounts();

  const total = useMemo(() => {
    if (data?.results) {
      return {
      total : data.results.
      // filter((p)=>p.type !== 're-registration').
      reduce((acc, curr) => acc + curr.members_this_month, 0),
      total_re_registration : data.results.
      filter((p)=>p.type === 're-registration').
      reduce((acc, curr) => acc + curr.members_this_month, 0),
      }
    }
    return 0;
  }, [data]);
  const insights = useMemo(() => {
    if (data?.results) {
      console.log(data.results);
      
      const this_month = data.results.reduce(
        (acc, curr) => acc + curr.members_this_month,
        0
      );
      const last_month = data.results.reduce(
        (acc, curr) => acc + curr.members_last_month,
        0
      );

      const percentageDifference =
        ((this_month - last_month) / (last_month == 0 ? 1 : last_month)) * 100;
        console.log('percentageDifference',percentageDifference);
        
      return {
        diff: percentageDifference.toFixed(0) || 0,
        // diff:1,
        this_month,
        last_month,
      };
    }
    return 0;
  }, [data]);

  const reduceByProduct = useMemo(() => {
    if (data?.results) {
      return data.results.sort((a,b)=>a.members_this_month < b.members_this_month).
      filter((p)=>p.members_this_month > 0).
      reduce((acc, curr) => {
     let key = curr.product_type
      if (!acc[key]) {
        acc[key]  = {
          new: 0,
          re_registration: 0,
        };
        }
        if (curr.type === 'new') {
          acc[key].new += curr.members_this_month;
        } else {
          acc[key].re_registration += curr.members_this_month;
        }
        return acc;
      }, {});
    }
    return 0;
  } , [data]);
  console.log('insights',insights);
  
  return (
    <Card
      title={t('dashboard.new_accounts')}
      insight={false}
      size='small'
      description={data?.description?.description}
      description_last_month={`${insights.last_month} altas el mes anterior`}
      percentage={insights.last_month == 0 && insights.this_month == 0 ? false: insights.diff}
      icon={''}
      TopInsight={
        <div className='gap-3 flex-1  pt-3 flex flex-col justify-between'>
        <div className='flex flex-row justify-between items-center'>
          <Tooltip
          title={()=>
            <div>
            <p>{total.total -total.total_re_registration } altas</p>
            <p>{total.total_re_registration} re matriculado</p>
            </div>}
          >
          <span
            className={`font-DrukWideMedium ${
              insights.diff > 0 ? 'text-primaryGreen' :  insights.diff == 0 ? 'text-primaryYellow': 'text-primaryRed'
            } flex items-center `}
          >
            <Statistic
              value={total.total}
              formatter={() => (
                <CountUp
                  start={0}
                  end={total.total}
                  className={`font-DrukWideMedium ${
                    insights.diff > 0 ? 'text-primaryGreen' :  insights.diff == 0 ? 'text-primaryYellow':'text-primaryRed'
                  } flex items-center text-4xl`}
                  separator=','
                />
              )}
            />
            {total.total > 0 &&
              (insights.diff > 0 ? (
                <BsArrowUp size={20} className='self-center' />
              ) : (
                <BsArrowDown size={20} className='self-center' />
              ))}
          </span>
          </Tooltip>
       
          </div>
          {data?.results && (
            <div className='gap-3 flex-1  pt-3 flex flex-col justify-between'>
              <div className='pt-4'>
                {Object.entries(reduceByProduct).
                map(([key, item], i) => (
                  <Tooltip key={i} title={()=>
                    <div>
                    <p>{item.new} altas</p>
                    <p>{item.re_registration} re matriculado</p>
                    </div>
                  } >
                  <div key={i} className='flex flex-row gap-4'>
                    <span
                      className={`${
                        insights.diff > 0
                          ? 'text-primaryGreen' : insights.diff == 0 ? 'text-primaryYellow':
                          'text-primaryRed'
                      } text-lg`}
                    >
                      {item.new + item.re_registration}
                    </span>
                    <span
                      className={`${
                        insights.diff > 0
                        ? 'text-primaryGreen' : insights.diff == 0 ? 'text-primaryYellow':
                        'text-primaryRed'
                      }  text-lg`}
                    >
                      {key}
                    </span>
                  </div>
                  </Tooltip>
                ))}
              </div>
            </div>
          )}
        </div>
      }
    />
  );
}
