import { t } from 'i18next';
import React, { useEffect ,useState} from 'react'
import { useGetLocations } from '../../api/locations';
import { useGetDocumentTypes } from '../../api/users';
import { canUpdateMemberLocation, isUserManager } from '../../services/can_user';
import { USE_FORM_PERSONAL_DATA } from '../../services/Constants/FORMS';
import CardRounded from '../CardRounded';
import es_ES from 'antd/locale/es_ES';
import dayjs from 'dayjs';
import { BsChevronDown } from 'react-icons/bs';
import { Select, ConfigProvider, DatePicker, Tooltip } from 'antd';
import { FaWhatsapp } from "react-icons/fa";
import WhatsAppSvg from '../../assets/icons/WhatsAppSvg';

export default function UserPersonalData({setFORM_MEMBER,FORM_MEMBER,isLoading,  document_types,isLoadingDocument_types,
  customer_type
}) {
    const FORM_PERSONAL_DATA = USE_FORM_PERSONAL_DATA();
    const ChangeMemberLocation = canUpdateMemberLocation();
    const [showAllInputs, setshowAllInputs] = useState(false);
    const { data: locations, isLoading: isLoadingLocations } = useGetLocations();

    const datepickerRef = React.useRef();
    const selectRef = React.useRef();
      const [PERSONAL_DATA, setPERSONAL_DATA] = useState(FORM_PERSONAL_DATA);

    useEffect(() => {
        if (!isLoadingDocument_types && !isLoading && !isLoadingLocations) {
          setPERSONAL_DATA(
            PERSONAL_DATA.map((item) => {
              if (item.name == 'document_type_id') {
                return {
                  ...item,
                  options: document_types.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })),
                };
              }
    
              if (item.name == 'location_id') {
                return {
                  ...item,
                  options: Object.values(
                    locations.reduce((acc, country) => {
                      const type = country.country_name;
                      if (!acc[type]) {
                        acc[type] = { label: type, options: [] };
                      }
                      acc[type].options.push({
                        label: country.name,
                        value: country.id,
                        // title: country.name,
                      });
                      return acc;
                    }, {})
                  ),
                };
              }
              return item;
            })
          );
        }
      }, [isLoadingDocument_types, isLoading, isLoadingLocations]);
  return (
    <div className='mt-10'>
    <h3 className='font-MessinaSansSemiBold text-xl pb-4'>Personal Info</h3>
    <CardRounded styles={``}>
      {PERSONAL_DATA.
      filter((t)=> customer_type =='Lead' ? t.name != 'username' && t.name != 'document_type_id'
      && t.name != 'gender' && t.name != 'document_number'  && t.name != 'birthdate' && t.name != 'instagram'
      : t)
      .map(
        (item, i) =>
          (i < 6 || showAllInputs) && (
            <div
              key={i}
              className='relative border-b-[1px] border-b-primaryGrey pt-7 pb-2 px-5 last:border-b-transparent flex flex-row items-center  '
            >
              <span className=' opacity-40 absolute top-2 font-MessinaSansSemiBold'>
                {item.label}
              </span>
              {item.type == 'date' ? (
                <ConfigProvider locale={es_ES}>
                  <DatePicker
                    allowClear={false}
                    ref={datepickerRef}
                    name={item.name}
                    placeholder=''
                    defaultValue={false}
                    value={
                      FORM_MEMBER[item.name]
                        ? dayjs(FORM_MEMBER[item.name], 'YYYY-MM-DD')
                        : false
                    }
                    style={{
                      width: '100%',
                      fontSize: 18,
                      // fontFamily: 'MessinaSansSemiBold',
                    }}
                    // defaultPickerValue={today} //using dayjs
                    onChange={(date, dateString) => {
                      datepickerRef.current.blur();
                      setFORM_MEMBER({
                        ...FORM_MEMBER,
                        [item.name]: dateString,
                      });
                    }}
                    format={'YYYY-MM-DD'}
                    className='shadow-none text-white appearance-none bg-transparent outline-none rounded-md h-12 w-full  border-none hover:bg-primaryGrey focus:bg-primaryGrey focus-within:bg-primaryGrey'
                  />
                </ConfigProvider>
              ) : item.type == 'select' ? (
                <Select
                  disabled={
                    item.name == 'location_id' && !ChangeMemberLocation
                  }
                  showSearch
                  placeholder={ customer_type == 'Lead'&&  'Se creara el prospecto en la sede'}
                  ref={selectRef}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(newValue) => {
                    selectRef.current.blur();
                    setFORM_MEMBER({
                      ...FORM_MEMBER,
                      [item.name]: newValue,
                    });
                  }}
                  loading={false}
                  // placeholder={item.label}
                  value={FORM_MEMBER ? FORM_MEMBER[item.name] : ''}
                  suffixIcon={
                    item.name == 'location_id' && !ChangeMemberLocation ? (
                      <></>
                    ) : (
                      <BsChevronDown color='#ADFF19' />
                    )
                  }
                  bordered={false}
                  style={{
                    width: '100%',
                    fontSize: 20,
                    fontFamily: 'MessinaSansSemiBold',
                  }}
                  options={item.options}
                  className='cursor-pointer  mt-3 mb-1 bg-primaryDark outline-none z-50  '
                />
              ) : (
                <>
                <input
                  className='w-full mt-4 mb-2 bg-primaryDark text-lg outline-none cursor-pointer'
                  disabled={item.name == 'username' && !isUserManager()}
                  type={item.type}
                  onChange={(e) => {
                    setFORM_MEMBER({
                      ...FORM_MEMBER,
                      [item.name]: e.target.value,
                    });
                  }}
                  value={FORM_MEMBER ? FORM_MEMBER[item.name] : ''}
                />
                {item.name == 'phone'&&FORM_MEMBER[item.name] && FORM_MEMBER[item.name].trim().length > 0  &&(
               <button
               onClick={() => 
                window.open('https://wa.me/' + FORM_MEMBER[item.name], '_blank')

               }
               >
                <Tooltip
                title={t('redirect_whatsapp', 'Redirigir a Whatsapp')}
                >
                <FaWhatsapp size={22} color='#ADFF19' />
            {/* <WhatsAppSvg bg={'#1EA05A'}  /> */}

                </Tooltip>

               </button>
                )}
                </>
              )}
              
            </div>
          )
      )}
      <div
        onClick={() => setshowAllInputs(!showAllInputs)}
        className=' bg-primaryGrey flex  justify-center py-4 cursor-pointer'
      >
        <p className=' font-MessinaSansSemiBold text-lg'>
          {showAllInputs
            ? t('show_less', 'Ver menos')
            : t('show_more', 'Ver más')}
        </p>
      </div>
    </CardRounded>
  </div>
  )
}
