import { useQuery } from '@tanstack/react-query';
import { fetchData } from '../services/utils/axiosInstance';
import { getLocationId } from '../services/utils/getLocation';
import axiosInstance from './prospects';
import dayjs from 'dayjs';
export const getSales = async () => {
  const location_id = getLocationId();
  return fetchData(`/dashboard_sales?location_id=${location_id}`);
};

export const useGetSalesChart = (enabled = { enabled: true }) => {
  const location_id = getLocationId();

  return useQuery({
    queryKey: ['dashboard_sales', location_id],
    queryFn: async () => getSales(),
    enabled: enabled.enabled,
  });
};

export const getCdpConvertion = async () => {
  const location_id = getLocationId();
  return fetchData(`/dashboard_conversion_cdp?location_id=${location_id}`);
};

export const useCdpConvertion = (enabled = { enabled: true }) => {
  const location_id = getLocationId();

  return useQuery({
    queryKey: ['dashboard_conversion_cdp', location_id],
    queryFn: async () => getCdpConvertion(),
    enabled: enabled.enabled,
  });
};
export const getLeadToCdpConvertion = async () => {
  const location_id = getLocationId();
  return fetchData(`/dashboard_conversion_lead_cdp?location_id=${location_id}`);
};

export const useLeadToCdpConvertion = (enabled = { enabled: true }) => {
  const location_id = getLocationId();

  return useQuery({
    queryKey: ['dashboard_conversion_lead_cdp', location_id],
    queryFn: async () => getLeadToCdpConvertion(),
    enabled: enabled.enabled,
  });
};
export const getConvertion2b = async () => {
  const location_id = getLocationId();
  return fetchData(`/dashboard_conversion_2b?location_id=${location_id}`);
};
export const useConvertion2b = (enabled = { enabled: true }) => {
  const location_id = getLocationId();

  return useQuery({
    queryKey: ['dashboard_conversion_2b', location_id],
    queryFn: async () => getConvertion2b(),
    select: (data) => ({
      ...data,
      results: data?.results.sort((a, b) =>
        dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1
      ),
    }),
    enabled: enabled.enabled,
  });
};
export const getAVGTicket = async () => {
  const location_id = getLocationId();
  return fetchData(`/dashboard_avg_ticket?location_id=${location_id}`);
};

export const useAVGTicket = () => {
  const location_id = getLocationId();

  return useQuery({
    queryKey: ['avg_ticket', location_id],
    queryFn: async () => getAVGTicket(),
  });
};
export const getCRMChart = async () => {
  const location_id = getLocationId();
  return fetchData(`/dashboard_crm?location_id=${location_id}`);
};

export const useGetCRMChart = () => {
  const location_id = getLocationId();

  return useQuery({
    queryKey: ['crm_chart', location_id],
    queryFn: async () => getCRMChart(),
  });
};
export const getAssignmentsChart = async () => {
  const location_id = getLocationId();
  return fetchData(`/dashboard_assigned_coach?location_id=${location_id}`);
};

export const useGetAssignmentsChart = () => {
  const location_id = getLocationId();

  return useQuery({
    queryKey: ['assignments', location_id],
    queryFn: async () => getAssignmentsChart(),
    // select: (data) => {
    //   return {
    //     ...data,
    //     results: data?.results.map((item) => ({
    //       ...item,
    //       week_number: Math.floor(Math.random() * 4) + 1,
    //     })),
    //   };
    // },
  });
};
export const getNewAccounts = async () => {
  const location_id = getLocationId();
  return fetchData(`/dashboard_new_accounts?location_id=${location_id}`);
};
export const useGetActiveMembers = (enabled = { enabled: true }) => {
  const location_id = getLocationId();

  return useQuery({
    queryKey: ['active_members', location_id],
    queryFn: async () => getActiveMembers(),
    enabled: enabled.enabled,
  });
};

export const getNewAccountsEvolution = async () => {
  const location_id = getLocationId();
  return fetchData(
    `/dashboard_active_members_evolution?location_id=${location_id}`
  );
};
export const useGetActiveMembersEvolution = (enabled = { enabled: true }) => {
  const location_id = getLocationId();

  return useQuery({
    queryKey: ['members_evolution', location_id],
    queryFn: async () => getNewAccountsEvolution(),
    enabled: enabled.enabled,
  });
};
export const getActiveMembers = async () => {
  const location_id = getLocationId();
  return fetchData(`/dashboard_active_members?location_id=${location_id}`);
};
export const useGetNewAccounts = () => {
  const location_id = getLocationId();

  return useQuery({
    queryKey: ['new_accounts', location_id],
    queryFn: async () => getNewAccounts(),
  });
};
export const getTraffic = async () => {
  const location_id = getLocationId();
  return fetchData(`/dashboard_conversion?location_id=${location_id}`);
};

export const useGetTraffic = () => {
  const location_id = getLocationId();

  return useQuery({
    queryKey: ['traffic', location_id],
    queryFn: async () => getTraffic(),
  });
};

export const useGetPXq = () => {
  const location_id = getLocationId();

  return useQuery({
    queryKey: ['pxq', location_id],
    queryFn: async () => getUsagepxq(),
    select: (data) => ({
      ...data,
      results: data?.results.filter((p) => p.sales_quantity > 0),
    }),
  });
};
export const getUsagepxq = async () => {
  const location_id = getLocationId();
  return fetchData(`/dashboard_sales_avg_ticket?location_id=${location_id}`);
};
export const useGetPXqSameMoment = () => {
  const location_id = getLocationId();

  return useQuery({
    queryKey: ['pxq_same_moment', location_id],
    queryFn: async () => getUsagepxqSameMoment(),
  });
};
export const getUsagepxqSameMoment = async () => {
  const location_id = getLocationId();
  return fetchData(
    `/dashboard_sales_avg_ticket_same_moment?location_id=${location_id}`
  );
};
export const getRenovation = async () => {
  const location_id = getLocationId();
  return fetchData(`/dashboard_renews?location_id=${location_id}`);
};
export const getRenovationSameMoment = async () => {
  const location_id = getLocationId();
  return fetchData(`/dashboard_renews_same_moment?location_id=${location_id}`);
};
export const useGetRenovationSameMoment = () => {
  const location_id = getLocationId();

  return useQuery({
    queryKey: ['renovation_same_moment', location_id],
    queryFn: async () => getRenovationSameMoment(),
  });
};
export const useGetRenovation = () => {
  const location_id = getLocationId();

  return useQuery({
    queryKey: ['renovation', location_id],
    queryFn: async () => getRenovation(),
  });
};
export default axiosInstance;
