import React, { useState, useEffect } from 'react';
import {
  Area,
  Label,
  XAxis,
  Tooltip,
  ComposedChart,
} from 'recharts';
import { BiDollar } from 'react-icons/bi';
import SalesTooltip from './utils/SalesTooltip';
import Card from './Card';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { formatPrice } from '../../services/utils/formatPrice';
import { t } from 'i18next';
export default function SalesIndicator({chart}) {
  const [data, setdata] = useState([]);

  const total = useMemo(() => {
    if (chart?.results) {
      return chart.results.filter((item) => dayjs(item.date,'YYYY-MM-DD').isSame(dayjs(),'month')).reduce((acc, curr) => acc + parseFloat(curr.total_sales), 0);
    }
    return 0;
  }, [chart]);
  const totalToday = useMemo(() => {
    if (chart?.results) {
      let today = chart.results.filter(
        (data) => data.date == dayjs().format('YYYY-MM-DD')
      );
      if (today.length > 0) {
        return formatPrice(today.reduce(
          (acc, curr) => acc + parseFloat(curr.total_sales),
          0
        ),)
      }
      return formatPrice(0);
    }
    return formatPrice(0);
  }, [chart]);
  const first_month = useMemo(() => {
    if (chart?.results) {
      let first = chart.results.sort((a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1))
      if (first && first.length > 0) {
        const date = dayjs(first[0].date).format('MMMM');
        return  date.charAt(0).toUpperCase() + date.slice(1);
      }
      return false;
    }
    return false;
  }, [chart]);
  const last_month = useMemo(() => {
    if (chart?.results) {
      let last = chart.results.sort((a, b) => (dayjs(b.date).isAfter(dayjs(a.date)) ? 1 : -1))
      if (last && last.length > 0) {
        const date = dayjs(last[0].date).format('MMMM');
        return  date.charAt(0).toUpperCase() + date.slice(1);
      }
      return false;
    }
    return false;
  }, [chart]);
  const difference = useMemo(() => {
    if (chart?.results) {
      const this_month = chart.results.filter((item) => dayjs(item.date).isSame(dayjs(),'month') ).reduce((acc, curr) => acc + parseFloat(curr.total_sales), 0);
      const last_month = chart.results.filter((item) => {
        // Inicio del mes pasado
        let startOfLastMonth = dayjs().subtract(1, 'month').startOf('month');
        
        // Misma fecha y hora actuales, pero en el mes pasado
        let sameMomentLastMonth = dayjs().subtract(1, 'month');
      
        // Fecha del elemento convertida a un objeto dayjs
        let itemDate = dayjs(item.date);
      
        // Comprobar si la fecha del elemento está en el rango deseado
        return (itemDate.isSame(startOfLastMonth) || itemDate.isAfter(startOfLastMonth)) && itemDate.isBefore(sameMomentLastMonth);
      }).reduce((acc, curr) => acc + parseFloat(curr.total_sales), 0);
      const percentageDifference = ((this_month - last_month) / (last_month == 0 ? 1 : last_month)) * 100;
      return {
        last_month:last_month,
        percentage:
        percentageDifference.toFixed(0) || 0}
    }
    return false;
  }, [chart]);
  useEffect(() => {
    if (chart?.results) {
      const monthlyData = chart.results.
      // filter((m)=> dayjs(m.date).isAfter).
      sort((a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1)).
      reduce((acc, data) => {
        const monthYear = dayjs(data.date).format('MMMM YYYY');
        if (!acc[monthYear]) {
          acc[monthYear] = {
            name: monthYear,
            // uv: 0,
            recurrent:0,
            'extended-quarterly': 0,
            'Other Sales':0,
            'monthly':0,
            'at-home':0,
            'extended-annual':0,
            'extended-other':0,
            'pay_per_use':0,
            'extended-biannual':0,
          };
        }
        // acc[monthYear].uv += parseFloat(data.total_sales);
        if(data.type == 'recurrent'){
          acc[monthYear].recurrent += parseFloat(data.total_sales)
          return acc;
        }
      
        if(data.type == 'Other Sales'){
          acc[monthYear]['Other Sales'] += parseFloat(data.total_sales)
          return acc;
        }
        if(data.type == 'monthly'){
          acc[monthYear]['monthly'] += parseFloat(data.total_sales)
          return acc;
        }
        if(data.type == 'at-home'){
          acc[monthYear]['at-home'] += parseFloat(data.total_sales)
          return acc;
        }
        if(data.type == 'extended-annual'){
          acc[monthYear]['extended-annual'] += parseFloat(data.total_sales)
          return acc;
        }
        if(data.type == 'extended-other'){
          acc[monthYear]['extended-other'] += parseFloat(data.total_sales)
          return acc;
        }
        if (data.type == 'extended-biannual') {
          acc[monthYear]['extended-biannual'] += parseFloat(data.total_sales)
          return acc;
        }
        if(data.type == 'extended-quarterly'){
          acc[monthYear]['extended-quarterly'] += parseFloat(data.total_sales)
          return acc;
        }
        if(data.type == 'pay_per_use'){
          acc[monthYear]['pay_per_use'] += parseFloat(data.total_sales)
          return acc;
        }
        return acc;
      }, {});
  
      setdata(Object.values(monthlyData));
    }
  }, [chart]);
  return (
    <Card
      icon={<BiDollar />}
      title={t('dashboard.sales_title')}
      insight={total}
      size='large'
      description={chart?.description?.description}
      description_last_month={`${formatPrice(difference.last_month)} vendidos el mes anterior al mismo momento`}
      Extraclass={`col-span-2`}
      todayTotal={totalToday}
      percentage={ difference.last_month == 0 ? false:difference.percentage}
    >
      <ComposedChart
        data={data}
        margin={{
          top: 5,
          bottom: -5,
        }}
      >
        {chart && chart.results?.length > 0 && (
          <XAxis dataKey='value' tickSize={0}>
            <Label
              value={first_month ? first_month : ''}
              position='insideLeft'
              className='text-white'
            />
            <Label
              value={last_month ? last_month : ''}
              position='insideRight'
              className=' text-white'
            />
          </XAxis>
        )}
        <Tooltip
          animationDuration={300}
          animationEasing='ease-in-out'
          cursor={{ stroke: '#CCCCCC', strokeWidtsh: 3, radius: 4 }}
          content={(props) => <SalesTooltip payload={props.payload}  />}
        />
       
<Area
  isAnimationActive={true}
  animationDuration={1000}
  type='monotone'
  dataKey='extended-quarterly'
  stroke={'#FFD500'}
  fill={'#FFD500'}
  strokeWidth={2}
  stackId={'1'}
/>
<Area
  isAnimationActive={true}
  animationDuration={1000}
  type='monotone'
  dataKey='Other Sales'
  stroke={'#1E89A0'}
  fill={'#1E89A0'}
  strokeWidth={2}
  stackId={'1'}
/>
<Area
  isAnimationActive={true}
  animationDuration={1000}
  type='monotone'
  dataKey='monthly'
  stroke={ '#CEF3F5'}
  fill={ '#CEF3F5'}
  stackId={'1'}
  strokeWidth={2}
/>
<Area
  isAnimationActive={true}
  animationDuration={1000}
  type='monotone'
  dataKey='at-home'
  stackId={'1'}
  stroke={'#1EA05A'}
  fill={'#1EA05A'}
  strokeWidth={2}
/>
<Area
  isAnimationActive={true}
  animationDuration={1000}
  type='monotone'
  dataKey='extended-annual'
  stackId={'1'}
  stroke={'#FF5733'}
  fill={'#FF5733'}
  strokeWidth={2}
/>
<Area
  isAnimationActive={true}
  animationDuration={1000}
  type='monotone'
  dataKey='extended-other'
  stackId={'1'}
  stroke={'#C70039'}
  fill={'#C70039'}
  strokeWidth={2}
/>
<Area
  isAnimationActive={true}
  animationDuration={1000}
  type='monotone'
  dataKey='pay_per_use'
  stackId={'1'}
  stroke={'#900C3F'}
  fill={'#900C3F'}
  strokeWidth={2}
/>
<Area
  isAnimationActive={true}
  animationDuration={1000}
  type='monotone'
  dataKey='extended-biannual'
  stackId={'1'}
  stroke={'#581845'}
  fill={'#581845'}
  strokeWidth={2}
/>
<Area
  isAnimationActive={true}
  animationDuration={1000}
  type='monotone'
  dataKey='recurrent'
  stroke={'#ADFF19'}
  fill={'#ADFF19'}
  strokeWidth={2}
  stackId={'1'}
/>
      </ComposedChart>
    </Card>
  );
}
