import React, { useState, useEffect } from 'react';
import { LineChart, Area, Label, XAxis, Tooltip, Line, Legend } from 'recharts';

import Card from './Card';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { t } from 'i18next';
import ConvertionTooltip from './utils/ConvertionTooltip';
export default function Convertion2b({ chart,view }) {
  const channel_types = useMemo(() => {
    if (chart?.results) {
      return (
        chart?.results.sort((a, b) =>
          dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1
        )
          .map((item) => ({
            ...item,
            [item.type]: parseFloat(item.quantity),
          }))
      );
      //reduce by month
    }
    return [];
  }, [chart]);
  const groupByMonth = (data) => {
    return data.reduce((acc, item) => {
      const year = new Date(item.date).getFullYear();
      const month = new Date(item.date).getMonth();
      const key = dayjs(item.date).format('YYYY-MM');
      if (!acc[key]) {
        acc[key] = {
          year,
          month: month + 1,
          Prospectos: 0,
          Members: 0,
          date: key,
        };
      }
      const channelKey = `c-${item.channel}`;
      const channelKeyConverted = `converted-${item.channel}`;

      if (acc[key][channelKey] === undefined) {
        acc[key][channelKey] = 0;
      }
      if (acc[key][channelKeyConverted] === undefined) {
        acc[key][channelKeyConverted] = 0;
      }

      if (item.type == 'Members') {
        acc[key][channelKeyConverted] += parseFloat(item.quantity);
        if(item.channel =='WEB' && item.sub_category){
          if (acc[key]['converted-'+item.sub_category] === undefined) {
            acc[key]['converted-'+item.sub_category] = 0;
          }
          acc[key]['converted-'+item.sub_category] += parseFloat(item.quantity);
        }
      }
      acc[key][item.type] =
        (acc[key][item.type] || 0) + parseFloat(item.quantity);

      if (item.type == 'Prospects') {
        acc[key][`c-${item.channel}`] += parseFloat(item.quantity);
        if(item.channel =='WEB' && item.sub_category){
          if (acc[key]['u-'+item.sub_category] === undefined) {
            acc[key]['u-'+item.sub_category] = 0;
          }
          acc[key]['u-'+item.sub_category] += parseFloat(item.quantity);
        }
      }
      return acc;
    }, {});
  };
  const formattedData = groupByMonth(channel_types);
  const groupByChannelView = (data) => {
    return data.reduce((acc, item) => {
      const year = new Date(item.date).getFullYear();
      const month = new Date(item.date).getMonth();
      const key = dayjs(item.date).format('YYYY-MM');
      if (!acc[key]) {
        acc[key] = {
          year,
          month: month + 1,
          Online: 0,
          Otros: 0,
          Referido:0,
          Visita:0,
          date: key,
        };
      }
      const categoryKey = `${item.category}`;
      const channelKeyConverted = `converted-${item.category}`;

      if (acc[key][categoryKey] === undefined) {
        acc[key][categoryKey] = 0;
      }
      if (acc[key][channelKeyConverted] === undefined) {
        acc[key][channelKeyConverted] = 0;
      }

      if (item.type == 'Members') {
        acc[key][channelKeyConverted] += parseFloat(item.quantity);
        // acc[key]['Prospects'] += parseFloat(item.quantity);
      }
      if (item.type == 'Prospects') {
        acc[key][categoryKey] =
        (acc[key][categoryKey] || 0) + parseFloat(item.quantity);

      }
    
      // if (item.type == 'Prospects') {
      //   acc[key][`c-${item.category}`] += parseFloat(item.quantity);
      // }
      return acc;
    }, {});
  };
  const LINES_GENERAL = ()=>{
    return (
      <> <Line
      type='monotone'
      isAnimationActive={false}
      dataKey='Members'
      stroke='#2BE947'
      activeDot={{ r: 8 }}
      label={<CustomizedLabel color={'#2BE947'} />}
    />
    <Line
      type='monotone'
      isAnimationActive={false}
      dataKey='Prospects'
      stroke='#FFA500'
      activeDot={{ r: 8 }}
      // strokeWidth={1}
      label={<CustomizedLabel color={'#FFA500'} />}
    /></>
    )
  }

  const formattedDataChannels = groupByChannelView(channel_types);
  const LINES_CHANNELVIEW = ()=>{
    return (
      <>
      <Line
      type='monotone'
      isAnimationActive={false}
      dataKey='Online'
      stroke='#2BE947'
      activeDot={{ r: 8 }}
      
      label={<CustomizedLabel color={'#2BE947'} />}
    />
    <Line
      type='monotone'
      isAnimationActive={false}
      dataKey='Referido'
      stroke='#2BE947'
      activeDot={{ r: 8 }}
      
      label={<CustomizedLabel color={'#2BE947'} />}
    />
     <Line
      type='monotone'
      isAnimationActive={false}
      dataKey='Visita'
      stroke='#2BE947'
      activeDot={{ r: 8 }}
      
      label={<CustomizedLabel color={'#2BE947'} />}
    />
    <Line
      type='monotone'
      isAnimationActive={false}
      dataKey='Otros'
      stroke='#2BE947'
      activeDot={{ r: 8 }}
      
      label={<CustomizedLabel color={'#2BE947'} />}
    />
{/* 
<Line
      type='monotone'
      isAnimationActive={false}
      dataKey='converted-Online'
      stroke='#FFA500'
      activeDot={{ r: 8 }}
      
      label={<CustomizedLabel color={'#FFA500'} />}
    />
    <Line
      type='monotone'
      isAnimationActive={false}
      dataKey='converted-Referido'
      stroke='#FFA500'
      activeDot={{ r: 8 }}
      
      label={<CustomizedLabel color={'#FFA500'} />}
    />
    <Line
      type='monotone'
      isAnimationActive={false}
      dataKey='converted-Otros'
      stroke='#FFA500'
      activeDot={{ r: 8 }}
      
      label={<CustomizedLabel color={'#FFA500'} />}
    /> */}
      </>

    )
  }

  const first_month = useMemo(() => {
    if (chart?.results) {
      let first = chart.results.sort((a, b) =>
        dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1
      );
      if (first && first.length > 0) {
        const date = dayjs(first[0].date).format('MMMM YYYY');
        return date.charAt(0).toUpperCase() + date.slice(1);
      }
      return false;
    }
    return false;
  }, [chart]);
  const last_month = useMemo(() => {
    if (chart?.results) {
      let last = chart.results.sort((a, b) =>
        dayjs(b.date).isAfter(dayjs(a.date)) ? 1 : -1
      );
      if (last && last.length > 0) {
        const date = dayjs(last[0].date).format('MMMM YYYY');
        return date.charAt(0).toUpperCase() + date.slice(1);
      }
      return false;
    }
    return false;
  }, [chart]);
  const CustomizedLabel = (props) => {
    const { x, y, value, color } = props;
    return (
      <text x={x} y={y} dy={-10} fill={color} fontSize={12} textAnchor='middle'>
        {value}
      </text>
    );
  };
  const difference = useMemo(() => {
    if (chart?.results && formattedData) {
      const this_month = 
      formattedData &&
      Object.keys(formattedData).length > 0 ?
        formattedData[
          Object.keys(formattedData)[Object.keys(formattedData).length - 1]
        ] 
        : { Members: 0, Prospects: 0 };

      const this_month_prospects =  this_month ?  this_month.Prospects : 0;
      const this_month_percentage = 
      (
        (this_month.Members / this_month.Prospects) *
        100
      ).toFixed(1);
      const last_month = Object.values(
        groupByMonth(
          chart.results.filter((item) => {
            let startOfLastMonth = dayjs()
              .subtract(1, 'month')
              .startOf('month');
            let sameMomentLastMonth = dayjs().subtract(1, 'month');
            let itemDate = dayjs(item.date);
            return (
              (itemDate.isSame(startOfLastMonth) ||
                itemDate.isAfter(startOfLastMonth)) &&
              itemDate.isBefore(sameMomentLastMonth)
            );
          })
        )
      )[0] || { Members: 0, Prospects: 0 };
      const last_month_prospects = !last_month ? 0 : last_month.Prospects;
      const last_month_percentage = !last_month
        ? 0
        : ((last_month.Members / last_month.Prospects) * 100).toFixed(1);
      const percentageDifference = (
        ((this_month_percentage - last_month_percentage) /
          (last_month_percentage == 0 ? 1 : last_month_percentage)) *
        100
      ).toFixed(0);
      const percentageDifferenceProspects = (
        ((this_month_prospects - last_month_prospects) /
          (last_month_prospects == 0 ? 1 : last_month_prospects)) *
        100
      ).toFixed(0);
      return {
        percentage_convertion: percentageDifference,
        same_moment_convertion: last_month_percentage,
        percentage_prospects: percentageDifferenceProspects,
        same_moment_prospects: last_month_prospects,
      };
    }
    return {
      percentage: 0,
      percentage_convertion: 0,
      percentage_prospects: 0,
      same_moment: 0,
    };
  }, [chart, formattedData]);
  return (
    <Card
      title={t('convertion', 'Conversión') + ' Leads'}
      size='large'
      description={chart?.description?.description}
      percentage2={ isNaN(difference.percentage_convertion)? false:
        t('convertion', 'Conversión') + ': ' + difference.percentage_convertion
      }
      description_last_month2={`${difference.same_moment_convertion}% de tasa de conversión al mismo momento el mes pasado`}
      percentage={ difference.same_moment_prospects == 0 ? false:
        t('dashboard.traffic_title') + ': ' + difference.percentage_prospects
      }
      
      description_last_month={`${difference.same_moment_prospects} prospectos al mismo momento el mes pasado`}
      Extraclass={`col-span-2 relative z-10`}
    >
      <LineChart

        data={Object.values( view == 'General' ?formattedData : formattedDataChannels)}
        margin={{
          top: 20,
          right: 10,
          left: 10,
          bottom: -20,
        }}
      >
        <Tooltip
          zIndex={9999}
          animationDuration={300}
          animationEasing='ease-in-out'
          cursor={{ stroke: '#CCCCCC', strokeWidtsh: 3, radius: 4 }}
          content={({ active, payload, label }) => <ConvertionTooltip active={active} payload={payload} view={view} />}
        />
        <Legend verticalAlign='bottom' height={34}
        
        formatter={(value, entry, index) => {
          return (
            <span className='text-sm'> 
              { view !== 'General' ? value:     value == 'Prospects' ? 'Prospectos' : 'Convertidos'}
            </span>
          );
        }
      }
        
        />
        {chart?.results && chart.results?.length > 0 && (
          <XAxis dataKey='value' tickSize={0}>
            <Label
              value={first_month ? first_month : ''}
              offset={-10}
              // position='left'
              position='insideLeft'
              className='text-white'
            />
            <Label
              value={last_month ? last_month : ''}
              position='insideRight'
              offset={-10}
              className=' text-white'
            />
          </XAxis>
        )}
     {view == 'General' ? LINES_GENERAL() : LINES_CHANNELVIEW()}
      </LineChart>
    </Card>
  );
}
