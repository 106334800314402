import React, { useEffect, useState } from 'react';
import { USE_PROSPECT_COMMON_FIELDS } from '../../services/Constants/FORMS';
import CardRounded from '../CardRounded';
import SelectCoach from '../helpers/SelectCoach';
import {
  useGetApproachTypes,
  useGetCampaings,
  useGetChannels,
} from '../../api/prospects';
import SearchReferrer from '../helpers/SearchReferrer';
import { isUserManager, isUserSuperAdmin } from '../../services/can_user';
import { BsChevronDown } from 'react-icons/bs';
import { Select } from 'antd';
import SelectUser from '../helpers/SelectUser';

export default function ProspectPersonalData({
  isProspect,
  FORM_MEMBER,
  member,
  setFORM_MEMBER,
  dischargeReasons,
  isLoadingDischarge,
  isLoading,
}) {
  const PROSPECT_COMMON_FIELDS = USE_PROSPECT_COMMON_FIELDS();

  const { data: approach_types, isLoading: isLoadingTypes } =
    useGetApproachTypes();
  const { data: campaings, isLoading: isLoadingCampaings } = useGetCampaings();
  const { data: channels, isLoading: isLoadingChannels } = useGetChannels();
  const [PROSPECT_FORM, SETPROSPECT_FORM] = useState(PROSPECT_COMMON_FIELDS);
  useEffect(() => {
    if (
      !isLoadingTypes &&
      !isLoadingCampaings &&
      !isLoadingChannels &&
      !isLoadingDischarge &&
      !isLoading
    ) {
      SETPROSPECT_FORM(
        PROSPECT_FORM.map((item) => {
          if (item.name == 'approach_type_id') {
            return {
              ...item,
              options: approach_types.map((item) => ({
                label: item.name,
                value: item.id,
              })),
            };
          }
          if (item.name == 'campaign_id') {
            return {
              ...item,
              options: campaings.map((item) => ({
                label: item.name,
                value: item.id,
              })),
            };
          }
          if (item.name == 'channel_id') {
            return {
              ...item,
              options: channels.
              // filter((l)=>
              //   FORM_MEMBER['channel_id'] == l.id || l.visibility).
              map((item) => ({
                label: item.name,
                value: item.id,
                disabled: !item.visibility,
              })),
            };
          }
          if (item.name == 'discharge_reason_id') {
            return {
              ...item,
              options: dischargeReasons
                .filter((d) => d.discharge_reason_type_id == 7)
                .map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
            };
          }

          return item;
        })
      );
    }
  }, [
    isLoadingTypes,
    isLoadingCampaings,
    isLoadingChannels,
    isLoadingDischarge,
    isLoading,
  ]);
  // console.log('initialUser',member);
  return (
    <div className='pt-10'>
      <h3 className='font-MessinaSansSemiBold text-xl pb-4'>Prospect Data</h3>

      <CardRounded styles={``}>
        {!isProspect && (
          <SelectCoach
          isLoading={isLoading}
          initialCoach={member?.prospect?.coach}
            isProspect={true}
            classNames={
              'mt-2 border-b-[1px] border-b-primaryGrey rounded-none pb-5'
            }
            disabled={!isUserSuperAdmin()}
            coach_location={member?.location_id}
            value={
              !isProspect
                ? FORM_MEMBER['prospect_coach_id']
                : FORM_MEMBER['coach_id']
            }
            setValue={(newValue, option) => {
              setFORM_MEMBER({
                ...FORM_MEMBER,
                // coach_id: newValue,
                // coach: {
                //   name: option?.label,
                // },
                prospect_coach_id: newValue,
                prospect:{
                  ...FORM_MEMBER.prospect,
                  coach: {
                    name: option?.label,
                    coach_user_id: option?.coach_user_id,
                  },
                  coach_id: newValue,
                }
              });
            }}
          />
        )}
        {PROSPECT_FORM.map((item, i) => (
          <div
            key={i}
            className='relative border-b-[1px] border-b-primaryGrey pt-7 pb-2 px-5 last:border-b-transparent flex flex-row items-center'
          >
            <span className=' opacity-40 absolute top-2 font-MessinaSansSemiBold'>
              {item.label}
            </span>
            {item.name == 'referrer_id' ? (
              <SearchReferrer
                handleChange={(newValue) => {
                  setFORM_MEMBER({
                    ...FORM_MEMBER,
                    [item.name]: newValue,
                  });
                }}
                valueSelect={FORM_MEMBER[item.name]}
              />
            ) : item.name == 'owner_user_id' ? (
              <SelectUser
                disabled={!isUserManager()}
                suffix
                initialUser={
                  {
                    id: member?.owner_user_id,
                    full_name: member?.owner_user_name,
                  }
                }
                width={'100%'}
                styles={`bg-transparent ml-0 !pl-0`}
                from_location={FORM_MEMBER.location_id}
                value={FORM_MEMBER[item.name]}
                setValue={(newValue) => {
                  setFORM_MEMBER({
                    ...FORM_MEMBER,
                    [item.name]: newValue,
                  });
                }}
              />
            ) : (
              <Select
                loading={
                  item.name == 'approach_type_id'
                    ? isLoadingTypes
                    : item.name == 'campaing_id'
                    ? isLoadingCampaings
                    : item.name == 'channel_id'
                    ? isLoadingChannels
                    : false
                }

              allowClear={item.name != 'channel_id'}
                value={FORM_MEMBER[item.name]}
                bordered={false}
                suffixIcon={<BsChevronDown color='#ADFF19' />}
                className='w-full text-white outline-none  border-none   rounded-md  font-DrukWideMedium py-1'
                style={{
                  fontSize: 44,
                }}
                
                onSelect={(prev)=>{
                 if(prev ==  FORM_MEMBER[item.name]) {
                  setFORM_MEMBER({
                    ...FORM_MEMBER,
                    [item.name]: null
                  });
                 }
                  
                }}
                onChange={(newValue) => {
                  setFORM_MEMBER({
                    ...FORM_MEMBER,
                    [item.name]: newValue,
                  });
                }}
                options={item.options}
              />
            )}
          </div>
        ))}
      </CardRounded>
    </div>
  );
}
