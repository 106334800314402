export default (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      console.log('entra');
      
      return {
        ...state,
        user: {
          ...action.payload.user,
          roles: action.payload.roles,
          locations: action.payload.locations || action.payload.user.locations,
        },
        isLogged: true,
      };
    case 'LOGOUT':
      return {
        ...state,
        user: null,
        isLogged: false,
      };
    case 'SET_LOCATIONS':
      return {
        ...state,
        locations: action.payload.reduceByCountry,
        availableLocations: action.payload.availableLocations,
      };
      case 'SET_CABLE':
        return{
          ...state,
          cable: action.payload
        }

    default:
      return state;
  }
};
